<template>
  <div class="login authentication" :style="background">
    <CContainer class="d-flex align-items-center min-vh-100">
      <CRow class="w-100">
        <CCol col="12">
          <CCard class="p-0 m-auto">
            <CCardBody class="p-4">
              <CRow>
                <CCol col="12" lg="12" class="pt-0">
                  <h4 class="mb-2">{{ $t('auth.Set_up_authentication') }}</h4>
                  <div class="d-flex mb-2">
                    <div class="step_number">1.</div>
                    <div class="step_content">
                      <span v-html="$t('auth.Download_the_authenticator_app')"></span>
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="step_number">2.</div>
                    <div class="step_content">
                      <span v-html="$t('auth.Click_on_add_account_button')"></span>
                    </div>
                  </div> 
                  <div class="d-flex mb-2">
                    <div class="step_number">3.</div>
                    <div class="step_content">
                      <span v-html="$t('auth.Select_scan_qr_code')"></span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div class="step_number">4.</div>
                    <div class="step_content">
                      <span v-html="$t('auth.Scan_the_qr_code_below')"></span>
                    </div>
                  </div>
                </CCol>
              </CRow>              
              <CRow>
                <CCol col="12" lg="12" class="text-center">
                  <loadingSpinner v-if="!authenticationQrImageUrl" mode="auto" :content="$t('auth.Generating_qr_code')"/>
                  <img v-else :src="authenticationQrImageUrl" class="qr_code"/>
                </CCol>
              </CRow>              
              <CRow>
                <CCol col="12" lg="12">
                  <div class="d-flex">
                    <div class="step_number">5.</div>
                    <div class="step_content">
                      <div class="mb-2">
                        <span v-html="$t('auth.Write_down_reset_code')"></span>
                        <span class="notice d-block mt-1">{{$t('auth.You_will_need_this_reset_code')}}</span>
                      </div>
                      <div class="auth_reset_code">
                        <loadingSpinner v-if="!authenticationResetCode" mode="inline" :content="$t('auth.Generating_reset_code')"/>
                        <div v-else class="d-flex align-items-center">
                          <span><b>{{authenticationResetCode}}</b></span>
                          <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="copyToClipboard(authenticationResetCode);">
                            <i class="fas fa-copy"/>
                          </CButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </CCol>
              </CRow>              
              <CRow>
                <CCol col="12" lg="12" class="pt-0 pb-0 text-left">
                  <div class="d-flex">
                    <div class="step_number">6.</div>
                    <div class="step_content">
                      <div class="mb-2">
                        <span v-html="$t('auth.Enter_6_digit_code')"></span>                        
                      </div>
                      <div>
                        <CodeInput :loading="false" id="userTokenInput" class="user_token_input" v-on:change="onUserTokenChange" v-on:complete="onUserTokenComplete"/>
                        
                        <div v-if="verifyingAuthenticator" class="mt-2">
                          <loadingSpinner mode="inline" :content="$t('auth.Checking_entered_code')"/>
                        </div>                       

                        <div v-if="errorMessage" class="mt-2">
                          <span>{{errorMessage}}</span>
                        </div>
                      </div>
                    </div>
                  </div>              
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="logo_placeholder">
      <div v-if="companyVariables.company_id_external && companyVariables.company_image_id">
        <img v-if="cdnBaseUrl" :src="cdnBaseUrl + '/base/dashboard/' + companyVariables.company_id_external + '/' + companyVariables.company_image_id + '.png'"/>
      </div>
      <div v-else-if="companyVariables.environment_id_external">
        <img v-if="companyVariables.environment_tag === 'harryhr'" src="img/brand/harry_hr_logo_23_06_2022.png"/>
        <img v-if="companyVariables.environment_tag === 'employalty'" src="img/brand/employalty_logo.png"/>
      </div>
    </div>      
  </div>  
</template>

<script>
import axios from 'axios';
import CodeInput from "vue-verification-code-input";

import loadingSpinner from '@/components/common/loadingSpinner.vue';

var twofactor = require("node-2fa");
var QRCode = require('qrcode');

export default {
  name: 'Setup',
  components: {
    CodeInput,
    loadingSpinner
  },
  data(){
    return {
      authenticationSecret: null,
      authenticationQrImageUrl: null,
      authenticationSecretTemp: null,
      authenticationResetCode: null,
      userToken: null,
      userData: {},
      companyVariables: {},
      errorMessage: null,
      verifyingAuthenticator: false,
      background : {
        backgroundImage: null,
        backgroundRepeat: 'repeat',
      }    
    }
  },
  methods: {    
    setupAuthentication() {
      // Store temp secret key value
      this.authenticationSecretTemp = this.authenticationSecret.secret;
      var vm = this;
      // Set the QR-code options
      let qrCodeOptions = { margin: 0, width: 200 };
      // Generate the authenticationQrImageUrl
      QRCode.toDataURL(this.authenticationSecret.uri, qrCodeOptions, function(err, dataUrl) {
        vm.authenticationQrImageUrl = dataUrl;
      });
    }, 
    verifyAuthenticator() {
      // Start the loader
      this.verifyingAuthenticator = true;
      // Reset the errorMessage value
      this.errorMessage = false;
      // Verify the userToken
      var verified = twofactor.verifyToken(this.authenticationSecretTemp, this.userToken);

      setTimeout(function() {
        // Stop the loader
        this.authenticatingUser = false;
        // Update the verifyingAuthenticator value
        this.verifyingAuthenticator = false;
        // Check the verified value
        if(verified === null) {
          this.errorMessage = this.$t('auth.Code_does_not_match');
        } else if(verified.delta < 0) {
          this.errorMessage = this.$t('auth.Code_entered_too_late');
        } else if(verified.delta > 0) {
          this.errorMessage = this.$t('auth.Code_entered_too_early');
        } else if(verified.delta === 0) {
          this.storeUserAuthenticationSecret();
        }
      }.bind(this), 500);
    },
    storeUserAuthenticationSecret() {
      let params = {};
      params.auth_secret = this.authenticationSecretTemp;

      axios.post(process.env.VUE_APP_API_URL + '/v1/core/authentication/secret', params)
      .then(res => {
        // Reset the errorMessage value
        this.errorMessage = false;        
        // Navigate to dashboard homepage
        this.$router.push('/')
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getCompanyVariables() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/variables/dashboard/company')
      .then(res => {
        this.companyVariables = res.data.data;
        // Set the dashboard title
        document.title = this.companyVariables.dashboard_title;        
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardDefaultPrimary', this.companyVariables.environment_primary_color);
        // Set default color variables
        let primaryColor = null;
        let secondaryColor = null;
        // Set the primaryColor value
        (this.companyVariables.primary_color) ? primaryColor = this.companyVariables.primary_color : primaryColor = this.companyVariables.environment_primary_color;
        // Set the dashboard primary color
        document.documentElement.style.setProperty('--DashboardPrimary', primaryColor);
        // Set the primaryColor value
        (this.companyVariables.secondary_color) ? secondaryColor = this.companyVariables.secondary_color : secondaryColor = this.companyVariables.environment_secondary_color;
        // Set the dashboard secondary color
        document.documentElement.style.setProperty('--DashboardSecondary', secondaryColor);
        // Set the dashboard font
        document.documentElement.style.setProperty('--DashboardFontFamily', this.companyVariables.font_family);
        // Set default color RGB variables
        let primaryColorRgb = null;
        // Set the primaryColor value
        (this.companyVariables.primary_color_rgb) ? primaryColorRgb = this.companyVariables.primary_color_rgb : primaryColorRgb = this.companyVariables.environment_primary_color_rgb;
        // Set the background image
        this.background.backgroundImage =  "linear-gradient(45deg, rgba(" + primaryColorRgb + ", 0.85), rgba(" + primaryColorRgb + ", 0.9)), url('./img/login/harry_hr_pattern.png')";                    
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    checkUserData() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/user/me')
      .then(res => {
        this.userData = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      }); 
    },
    onUserTokenChange(data) {
      // Reset the errorMessage value
      this.errorMessage = false;      
      // Update userToken value
      this.userToken = data;
    },
    onUserTokenComplete(data) {
      // Reset the errorMessage value
      this.errorMessage = false;      
      // Update userToken value
      this.userToken = data;
      // Trigger verifyAuthenticator method
      this.verifyAuthenticator();
    },
    copyToClipboard(resetCode) {
      // Create temporary textarea for copy
      var copy = document.createElement("textarea");
      document.body.appendChild(copy);
      copy.value = resetCode;
      copy.select();
      document.execCommand("copy");
      document.body.removeChild(copy);
      // Show message
      this.$buefy.toast.open({ message: this.$t('common.Copied_to_clipboard'), type: 'is-success', duration: 2000 });
    }       
  },
  mounted: function() {
    this.getCompanyVariables();
    this.checkUserData();

    setTimeout(function() {
      this.authenticationSecret = twofactor.generateSecret({ name: this.companyVariables.brand_name, account: this.userData.username });
      
      this.authenticationResetCode = this.$CryptoJS.AES.encrypt(this.userData.email, this.authenticationSecret.secret).toString();

      this.setupAuthentication();      
    }.bind(this), 1000);
  }
}
</script>
